import React from 'react';

const CustomSelect = ({ id, name, options, value, onChange }) => {
    return (
        <select id={id} name={name} className="custom-select" required value={value} onChange={onChange}>
            <option value="">Таңдау</option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default CustomSelect;