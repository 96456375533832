import React, { useState } from 'react';
import '../styles/HelpScreen.css';
import DangerModal from './DangerModal';
import { db } from '../config';
import { addDoc, collection } from 'firebase/firestore';

const FeedbackForm = ({ onSubmit }) => {
    const [feedback, setFeedback] = useState('');
    const [showDanger, setShowDanger] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const feedbackCollection = collection(db, 'feedback');
            await addDoc(feedbackCollection, { message: feedback });
            onSubmit();
            setFeedback('');
            setShowDanger(true);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };    

    return (
        <div>
            <form className="feedback-form" onSubmit={handleSubmit}>
                <h2>Пікір қалдыру</h2>
                <textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Пікіріңізді білдіру..."
                    required
                />
                <button type="submit">Жіберу</button>
            </form>
            {showDanger && (
                <DangerModal
                    message="Пікіріңізді міндетті түрде ескеретін боламыз!"
                    onClose={() => setShowDanger(false)}
                />
            )}
        </div>
    );
};

export default FeedbackForm;