import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config';
import DangerMessage from './DangerMessage';
import '../styles/Home.css';

import Region1 from '../svg/01.svg';
import Region2 from '../svg/02.svg';
import Region3 from '../svg/03.svg';
import Region4 from '../svg/04.svg';
import Region5 from '../svg/05.svg';
import Region6 from '../svg/06.svg';
import Region7 from '../svg/07.svg';
import Region8 from '../svg/08.svg';
import Region9 from '../svg/09.svg';
import Region10 from '../svg/10.svg';
import Region11 from '../svg/11.svg';
import Region12 from '../svg/12.svg';
import Region13 from '../svg/13.svg';
import Region14 from '../svg/14.svg';
import Region15 from '../svg/15.svg';
import Region16 from '../svg/16.svg';
import Region17 from '../svg/17.svg';

import mainIcon from '../icons/main.png';
import anizIcon from '../icons/aniz.png';
import zertteulerIcon from '../icons/zertteuler.png';

// icons
import addIcon from '../icons/add.svg';
import helpIcon from '../icons/help.svg';
import menuIcon from '../icons/menu.svg';

const regionImages = [
  Region1, Region2, Region3, Region4, Region5, Region6, Region7,
  Region8, Region9, Region10, Region11, Region12, Region13,
  Region14, Region15, Region16, Region17
];

const regionNames = [
  'Батыс Қазақстан', 'Қостанай', 'Солтүстік Қазақстан', 'Павлодар', 'Шығыс Қазақстан', 'Ақмола',
  'Атырау', 'Ақтөбе', 'Ұлытау', 'Қарағанды', 'Абай',
  'Маңғыстау', 'Қызылорда', 'Түркістан', 'Жамбыл', 'Алматы облысы', 'Жетісу'
];

function Home() {
  const navigate = useNavigate();
  const markerRef = useRef(null);
  const [clickedRegion, setClickedRegion] = useState(null);
  const [regions, setRegions] = useState([]);
  const [regionNamesList, setRegionNamesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalInfo, setModalInfo] = useState({ visible: false, data: null });
  const [error, setError] = useState(null);
  const [headerText, setHeaderText] = useState('Ziyarat.kz');
  const [activeTab, setActiveTab] = useState('info');
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const fetchedRegions = await Promise.all(regionNames.map(async (name, index) => {
          const regionCollection = collection(db, name);
          const regionSnapshot = await getDocs(regionCollection);
          const regionData = regionSnapshot.docs.map(doc => doc.data())[0];
          return { id: index + 1, name, status: regionData?.status || 'Белгісіз' };
        }));
        setRegions(fetchedRegions);
      } catch (err) {
        setError('Аймақ деректерін алу сәтсіз аяқталды');
      } finally {
        setLoading(false);
      }
    };
    fetchRegions();
  }, []);

  const handleRegionClick = useCallback(async (regionNumber) => {
    try {
      if (clickedRegion === regionNumber) {
        setClickedRegion(null);
        setRegionNamesList([]);
        setHeaderText('Ziyarat.kz');
      } else {
        setClickedRegion(regionNumber);
        const regionCollection = collection(db, regionNames[regionNumber - 1]);
        const regionSnapshot = await getDocs(regionCollection);
        const names = regionSnapshot.docs.map(doc => doc.data().name);
        setRegionNamesList(names);
        setHeaderText(regionNames[regionNumber - 1]);
      }
    } catch (err) {
      setError('Аймақ атауларын алу сәтсіз аяқталмады');
    }
  }, [clickedRegion]);

  const handleCardClick = useCallback(async (name) => {
    try {
      const regionCollection = collection(db, regionNames[clickedRegion - 1]);
      const regionSnapshot = await getDocs(regionCollection);
      const cityData = regionSnapshot.docs.find(doc => doc.data().name === name).data();
      setModalInfo({ visible: true, data: cityData });
      setActiveTab('info');
    } catch (err) {
      setError('Қала деректерін алу сәтсіз аяқталмады');
    }
  }, [clickedRegion]);

  const closeModal = () => setModalInfo({ visible: false, data: null });
  const closeError = () => setError(null);
  const toggleExpand = (id) => setExpandedIndex(expandedIndex === id ? null : id);

  const buildRoute = useCallback(async () => {
    try {
      const getUserLocation = () => new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => resolve({ lat: position.coords.latitude, lng: position.coords.longitude }),
            (error) => reject(error)
          );
        } else {
          reject(new Error('Geolocation is not supported by this browser.'));
        }
      });

      const userLocation = await getUserLocation();
      const [lat, lng] = modalInfo.data.point.split(',').map(Number);
      const destination = { lat, lng };
      const directionsService = new window.google.maps.DirectionsService();

      const result = await new Promise((resolve, reject) => {
        directionsService.route({
          origin: new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
          destination: new window.google.maps.LatLng(destination.lat, destination.lng),
          travelMode: window.google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            resolve(response);
          } else {
            reject(`Directions request failed due to ${status}`);
          }
        });
      });

      return result;
    } catch (error) {
      console.error('Error building route:', error);
      return false;
    }
  }, [modalInfo.data]);

  const handleRouteClick = async () => {
    const routeBuilt = await buildRoute();
    if (routeBuilt) {
      navigate('/new-route', { state: { modalInfo } });
    } else {
      setError('Бағыт құру сәтсіз аяқталды');
    }
  };

  const renderTabContent = () => {
    if (activeTab === 'aniz') {
      return modalInfo.data.aniz && modalInfo.data.aniz.length > 0 ? (
        modalInfo.data.aniz.map((aniz) => (
          <div key={aniz.id} className={`aniz-item ${expandedIndex === aniz.id ? 'expanded' : ''}`}>
            <h3 onClick={() => toggleExpand(aniz.id)} className="aniz-atauy">
              {aniz.atauy}
            </h3>
            <div className={`aniz-details ${expandedIndex === aniz.id ? 'show' : ''}`}>
              <p>{aniz.aniz}</p>
              {aniz.audio && (
                <audio controls src={aniz.audio}>
                  Браузеріңіз аудио элементті қолдамайды.
                </audio>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>Аңыз мәліметтері жоқ</p>
      );
    }

    if (activeTab === 'zertteuler') {
      return modalInfo.data.zertteuler && modalInfo.data.zertteuler.length > 0 ? (
        modalInfo.data.zertteuler.map((item, index) => (
          <div key={index}>
            <br />
            <p>{item}</p>
          </div>
        ))
      ) : (
        <p>Зерттеулер мәліметтері жоқ</p>
      );
    }

    return (
      <div>
        <h2>{modalInfo.data.name}</h2>
        <button className="route-button" onClick={handleRouteClick}>Бағыт</button>
        <div className="images-container">
          {modalInfo.data.images.map((image, idx) => (
            <img key={idx} src={image} alt={`${modalInfo.data.name} ${idx + 1}`} />
          ))}
        </div>
        <p>{modalInfo.data.description}</p>
      </div>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (markerRef.current && !markerRef.current.contains(event.target)) {
        setClickedRegion(null);
        setRegionNamesList([]);
        setHeaderText('Ziyarat.kz');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (loading) {
    return <div className='contentSpinner'><div className="spinner"></div></div>;
  }

  return (
    <div className="newTestMap">
      <header className="map-header">{headerText}</header>
      {error && <DangerMessage message={error} onClose={closeError} />}
      <div className={`menu-bar ${isMenuExpanded ? 'expanded' : ''}`} onClick={() => setIsMenuExpanded(!isMenuExpanded)}>
        <img src={menuIcon} alt="Menu" />
        {isMenuExpanded && (
          <div className="expanded-menu">
            <Link to="/add">
              <img src={addIcon} alt="Add" />
            </Link>
            <Link to="/help">
              <img src={helpIcon} alt="Help" />
            </Link>
          </div>
        )}
      </div>
      <div className={`map-scroller ${clickedRegion ? 'blurred' : ''}`}>
        {regions.map((region, index) => (
          <img
            key={region.id}
            src={regionImages[index]}
            alt={`Region ${index + 1}`}
            className={`region region${index + 1} ${clickedRegion === index + 1 ? 'clicked zoomed' : ''} ${clickedRegion && clickedRegion !== index + 1 ? 'blur' : ''}`}
            onClick={() => handleRegionClick(index + 1)}
          />
        ))}
        {clickedRegion && (
          <div className="marker" ref={markerRef}>
            <div className="cards-container">
              {regionNamesList.map((name, idx) => (
                <div className="card" key={idx} onClick={() => handleCardClick(name)}>
                  <p>{name}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {modalInfo.visible && (
        <div className="modal-map">
          <div className="modal-content-map">
            <span className="close" onClick={closeModal}>&times;</span>
            <div className="tabs">
              <button className={`tab ${activeTab === 'aniz' ? 'active' : ''}`} onClick={() => setActiveTab('aniz')}><img src={anizIcon} alt='Aniz' /></button>
              <button className={`tab ${activeTab === 'info' ? 'active' : ''}`} onClick={() => setActiveTab('info')}><img src={mainIcon} alt='Main' /></button>
              <button className={`tab ${activeTab === 'zertteuler' ? 'active' : ''}`} onClick={() => setActiveTab('zertteuler')}><img src={zertteulerIcon} alt='Zertteuler' /></button>
            </div>
            <div className="tab-content">
              {renderTabContent()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;