import React, { useState } from 'react';
import { db, storage } from '../config';
import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DangerModal from './DangerModal';
import AddTaxiForm from './AddTaxiForm';
import CustomSelect from './CustomSelect';
import '../styles/Add.css';
import { useSwipeable } from 'react-swipeable';

const AddScreen = () => {
    const [activeTab, setActiveTab] = useState('place');
    const [anizCount, setAnizCount] = useState(1);
    const [zertteulerCount, setZertteulerCount] = useState(1);
    const [formData, setFormData] = useState({
        fio: '',
        number: '',
        name: '',
        description: '',
        city: '',
        type: '',
        point: '',
        zertteuler: [''],
        aniz: [{ atauy: '', aniz: '', audio: null }],
    });
    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddAniz = () => {
        setAnizCount(anizCount + 1);
        setFormData({
            ...formData,
            aniz: [...formData.aniz, { atauy: '', aniz: '', audio: null }],
        });
    };

    const handleRemoveAniz = () => {
        if (anizCount > 1) {
            setAnizCount(anizCount - 1);
            setFormData({
                ...formData,
                aniz: formData.aniz.slice(0, -1),
            });
        }
    };

    const handleAddZertteuler = () => {
        setZertteulerCount(zertteulerCount + 1);
        setFormData({
            ...formData,
            zertteuler: [...formData.zertteuler, ''],
        });
    };

    const handleRemoveZertteuler = () => {
        if (zertteulerCount > 1) {
            setZertteulerCount(zertteulerCount - 1);
            setFormData({
                ...formData,
                zertteuler: formData.zertteuler.slice(0, -1),
            });
        }
    };

    const handleAnizChange = (index, e) => {
        const { name, value } = e.target;
        const newAniz = [...formData.aniz];
        newAniz[index] = {
            ...newAniz[index],
            [name]: value,
        };
        setFormData({
            ...formData,
            aniz: newAniz,
        });
    };

    const handleZertteulerChange = (index, e) => {
        const newZertteuler = [...formData.zertteuler];
        newZertteuler[index] = e.target.value;
        setFormData({
            ...formData,
            zertteuler: newZertteuler,
        });
    };

    const handleFileChange = (e) => {
        if (e.target.name === "images") {
            setImages([...e.target.files]);
        } else {
            const index = parseInt(e.target.id.replace('audio', '')) - 1;
            const newAniz = [...formData.aniz];
            if (e.target.files.length > 0) {
                newAniz[index].audio = e.target.files[0];
            } else {
                newAniz[index].audio = null;
            }
            setFormData({
                ...formData,
                aniz: newAniz,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const imageUrls = await Promise.all(
                images.map(async (image) => {
                    const imageRef = ref(storage, `images/${image.name}`);
                    await uploadBytes(imageRef, image);
                    return getDownloadURL(imageRef);
                })
            );

            const audioUrls = await Promise.all(
                formData.aniz.map(async (aniz, index) => {
                    if (aniz.audio) {
                        const audioRef = ref(storage, `audio/${aniz.audio.name}`);
                        await uploadBytes(audioRef, aniz.audio);
                        return getDownloadURL(audioRef);
                    }
                    return null;
                })
            );

            const dataToSave = {
                fio: formData.fio || null,
                number: formData.number || null,
                name: formData.name || null,
                description: formData.description || null,
                city: formData.city || null,
                type: formData.type || null,
                point: formData.point || null,
                zertteuler: formData.zertteuler || null,
                aniz: formData.aniz.map((aniz, index) => ({
                    ...aniz,
                    audioUrl: audioUrls[index] || null,
                })),
                images: imageUrls || null,
            };

            await addDoc(collection(db, 'places'), dataToSave);

            setFormData({
                fio: '',
                number: '',
                name: '',
                description: '',
                city: '',
                type: '',
                point: '',
                zertteuler: [''],
                aniz: [{ atauy: '', aniz: '', audio: null }],
            });
            setImages([]);
            setModalMessage('Деректер сәтті қосылды!');
            setShowModal(true);
        } catch (error) {
            console.error('Құжат қосу кезіндегі қате: ', error);
            setModalMessage('Деректерді қосу кезінде қате кетті, қайтадан көріңіз.');
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalMessage('');
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setActiveTab('taxi'),
        onSwipedRight: () => setActiveTab('place'),
    });

    const cities = [
        { value: 'Алматы облысы', label: 'Алматы облысы' },
        { value: 'Абай', label: 'Абай' },
        { value: 'Ақмола', label: 'Ақмола' },
        { value: 'Атырау', label: 'Атырау' },
        { value: 'Ақтөбе', label: 'Ақтөбе' },
        { value: 'Ұлытау', label: 'Ұлытау' },
        { value: 'Шығыс Қазақстан', label: 'Шығыс Қазақстан' },
        { value: 'Маңғыстау', label: 'Маңғыстау' },
        { value: 'Солтүстік Қазақстан', label: 'Солтүстік Қазақстан' },
        { value: 'Павлодар', label: 'Павлодар' },
        { value: 'Қарағанды', label: 'Қарағанды' },
        { value: 'Қостанай', label: 'Қостанай' },
        { value: 'Қызылорда', label: 'Қызылорда' },
        { value: 'Түркістан', label: 'Түркістан' },
        { value: 'Жамбыл', label: 'Жамбыл' },
        { value: 'Жетісу', label: 'Жетісу' },
        { value: 'Батыс Қазақстан', label: 'Батыс Қазақстан' },
    ];

    const placeTypes = [
        { value: 'holy', label: 'Киелі орын' },
        { value: 'history', label: 'Тарихи-мәдени орын' },
        { value: 'hotel', label: 'Қонақ үй' },
    ];

    return (
        <div {...swipeHandlers}>
            <header className="map-header">Ақпарат қосу</header>
            <div className="tab-buttons">
                <button onClick={() => setActiveTab('place')} className={activeTab === 'place' ? 'active' : ''}>Деректерді жүктеу</button>
                <button onClick={() => setActiveTab('taxi')} className={activeTab === 'taxi' ? 'active' : ''}>Қызмет түрін ұсыну</button>
            </div>
            {activeTab === 'place' && (
                <form className='container' id="addDataForm" onSubmit={handleSubmit}>
                    <label htmlFor="fio">Толық аты жөніңіз:</label>
                    <input type="text" id="fio" name="fio" placeholder="Толық аты жөніңіз" required value={formData.fio} onChange={handleInputChange} />

                    <label htmlFor="number">Телефон нөміріңіз:</label>
                    <input type="text" id="number" name="number" placeholder="Телефон нөміріңіз" required value={formData.number} onChange={handleInputChange} />

                    <label htmlFor="name">Атауы:</label>
                    <input type="text" id="name" name="name" placeholder="Атауы" required value={formData.name} onChange={handleInputChange} />

                    <label htmlFor="description">Сипаттама:</label>
                    <input type="text" id="description" name="description" placeholder="Сипаттама" required value={formData.description} onChange={handleInputChange} />

                    <label htmlFor="city">Қаласы:</label>
                    <CustomSelect id="city" name="city" options={cities} value={formData.city} onChange={handleInputChange} />

                    <label htmlFor="type">Орын түрі:</label>
                    <CustomSelect id="type" name="type" options={placeTypes} value={formData.type} onChange={handleInputChange} />

                    <label htmlFor="point">Орны</label>
                    <input type="text" id="point" name="point" placeholder="lat, lng" required value={formData.point} onChange={handleInputChange} />

                    <label htmlFor="zertteuler">Зерттеулер:</label>
                    {formData.zertteuler.map((zertteu, index) => (
                        <div key={index} className="zertteulerContainer">
                            <input type="text" id={`zertteuler${index}`} name="zertteuler" placeholder="Зерттеулер..." required value={zertteu} onChange={(e) => handleZertteulerChange(index, e)} />
                        </div>
                    ))}
                    <button type="button" id="addZertteuler" onClick={handleAddZertteuler}>+</button>
                    <button type="button" id="removeZertteuler" onClick={handleRemoveZertteuler}>-</button>

                    <label>Аңыздар:</label>
                    {formData.aniz.map((aniz, index) => (
                        <div key={index} className="anizContainer">
                            <input type="text" name="atauy" placeholder="Атауы..." value={aniz.atauy} onChange={(e) => handleAnizChange(index, e)} />
                            <input type="text" name="aniz" placeholder="Аңыз..." value={aniz.aniz} onChange={(e) => handleAnizChange(index, e)} />
                            <div>
                                <label className="custom-file-upload" htmlFor={`audio${index + 1}`}>Аудио файл:</label>
                                <input type="file" id={`audio${index + 1}`} name={`audio${index + 1}`} accept="audio/*" onChange={handleFileChange} />
                            </div>
                        </div>
                    ))}
                    <button type="button" id="addAniz" onClick={handleAddAniz}>+</button>
                    <button type="button" id="removeAniz" onClick={handleRemoveAniz}>-</button>

                    <label className="custom-file-upload" htmlFor="images">Суреттерді жүктеп салу:</label>
                    <input type="file" id="images" name="images" multiple required onChange={handleFileChange} />

                    <button type="submit">Жіберу</button>
                </form>
            )}
            {activeTab === 'taxi' && <AddTaxiForm />}
            <DangerModal show={showModal} message={modalMessage} onClose={handleCloseModal} />
        </div>
    );
};

export default AddScreen;