import React, { useState } from 'react';
import { db } from '../config';
import { addDoc, collection } from 'firebase/firestore';
import DangerModal from './DangerModal';
import '../styles/Add.css';

const AddTaxiForm = () => {
    const [taxiData, setTaxiData] = useState({
        name: '',
        description: '',
        contact: '',
        price: '',
    });
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleTaxiInputChange = (e) => {
        const { name, value } = e.target;
        setTaxiData({
            ...taxiData,
            [name]: value,
        });
    };

    const handleTaxiSubmit = async (e) => {
        e.preventDefault();
        try {
            const taxiDataToSave = {
                name: taxiData.name || null,
                description: taxiData.description || null,
                contact: taxiData.contact || null,
                price: taxiData.price || null,
            };

            if (!taxiData.name || !taxiData.contact || !taxiData.price) {
                throw new Error("Сұрау жіберу үшін қажетті өріс(тер) толтырылуы керек");
            }

            await addDoc(collection(db, 'taxi'), taxiDataToSave);

            setTaxiData({
                name: '',
                description: '',
                contact: '',
                price: '',
                status: 'send',
            });

            setModalMessage('Такси қызметі сәтті қосылды!');
            setShowModal(true);
        } catch (error) {
            console.error('Құжат қосу кезіндегі қате: ', error);
            setModalMessage('Такси қызметін қосу кезінде қате кетті, қайтадан көріңіз.');
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalMessage('');
    };

    return (
        <div>
            <form className='container' id="addTaxiForm" onSubmit={handleTaxiSubmit}>
                <label htmlFor="taxiName">Қызмет атауы:</label>
                <input type="text" id="taxiName" name="name" placeholder="Қызмет атауы" required value={taxiData.name} onChange={handleTaxiInputChange} />

                <label htmlFor="taxiDescription">Сипаттама:</label>
                <input type="text" id="taxiDescription" name="description" placeholder="Сипаттама" required value={taxiData.description} onChange={handleTaxiInputChange} />

                <label htmlFor="taxiContact">Байланыс нөмірі:</label>
                <input type="text" id="taxiContact" name="contact" placeholder="Байланыс нөмірі" required value={taxiData.contact} onChange={handleTaxiInputChange} />

                <label htmlFor="taxiPrice">Бағасы:</label>
                <input type="text" id="taxiPrice" name="price" placeholder="Бағасы" required value={taxiData.price} onChange={handleTaxiInputChange} />

                <button type="submit">Жіберу</button>
            </form>
            <DangerModal show={showModal} message={modalMessage} onClose={handleCloseModal} />
        </div>
    );
};

export default AddTaxiForm;