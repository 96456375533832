import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyC1lDb3LlgLnV8tv50ayY5SBn2bWuN4Rrg",
    authDomain: "turism-415110.firebaseapp.com",
    projectId: "turism-415110",
    storageBucket: "turism-415110.appspot.com",
    messagingSenderId: "239747645578",
    appId: "1:239747645578:web:90370a907c78ef18cfad73",
    measurementId: "G-GMYSMGV82Y"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };