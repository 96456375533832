import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/RouteScreen.css';

import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../config';

import tour from "../icons/tour.png";
import taxi from "../icons/taxi.png";
import train from "../icons/train.png";
import home from "../icons/home.png";
import twogis from "../icons/2gis.png";

import marker_a from "../icons/marker_a.svg";
import marker_b from "../icons/marker_b.svg";

import DangerModal from './DangerModal';

const NewRouteScreen = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const mapRef = useRef(null);
    const { modalInfo } = state;
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [serviceData, setServiceData] = useState([]);
    const [routeInfo, setRouteInfo] = useState(null);
    const [userPosition, setUserPosition] = useState(null);

    useEffect(() => {
        if (!state || !state.modalInfo) {
            navigate('/');
            return;
        }

        const initMap = (userPosition) => {
            const map = new window.google.maps.Map(mapRef.current, {
                center: userPosition,
                zoom: 15,
                mapTypeControl: false,
                streetViewControl: false,
                styles: [
                    {
                        featureType: "all",
                        elementType: "labels",
                        stylers: [
                            { visibility: "off" }
                        ]
                    }
                ]
            });

            const directionsService = new window.google.maps.DirectionsService();
            const directionsRenderer = new window.google.maps.DirectionsRenderer({
                suppressMarkers: true,
                polylineOptions: {
                    strokeColor: "#86a3aa",
                    strokeWeight: 5
                }
            });
            directionsRenderer.setMap(map);

            const request = {
                origin: userPosition,
                destination: modalInfo.data.point,
                travelMode: window.google.maps.TravelMode.DRIVING,
            };

            directionsService.route(request, (result, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(result);
                    map.setCenter(userPosition);

                    const leg = result.routes[0].legs[0];
                    setRouteInfo({
                        distance: leg.distance.text,
                        duration: leg.duration.text
                    });
                    new window.google.maps.Marker({
                        position: userPosition,
                        map: map,
                        icon: marker_a,
                        title: 'Starting Point'
                    });
                    const [destinationLat, destinationLng] = modalInfo.data.point.split(',').map(Number);
                    new window.google.maps.Marker({
                        position: { lat: destinationLat, lng: destinationLng },
                        map: map,
                        icon: marker_b,
                        title: 'Destination'
                    });
                } else {
                    console.error('Directions request failed due to ', status);
                }
            });
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setUserPosition(userPosition);
                    initMap(userPosition);
                },
                (error) => {
                    console.error('Error getting user position', error);
                    initMap({ lat: 48.0196, lng: 66.9237 });
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            initMap({ lat: 48.0196, lng: 66.9237 });
        }
    }, [state, navigate, modalInfo.data.point]);

    const handleServiceButtonClick = async (service) => {
        try {
            const serviceCollection = collection(db, service);
            const querySnapshot = await getDocs(query(serviceCollection, where("status", "==", "ready"), where("city", "==", modalInfo.data.city)));

            if (querySnapshot.empty) {
                setModalMessage("Бұл қызмет үшін деректер табылмады.");
                setShowModal(true);
                return;
            }

            setServiceData(querySnapshot.docs.map(doc => doc.data()));
        } catch (error) {
            console.error("Error fetching service data:", error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const open2GIS = () => {
        if (userPosition) {
            const { lat: startLat, lng: startLng } = userPosition;
            const [lat, lng] = modalInfo.data.point.split(',').map(Number);
            const url = `https://2gis.kz/directions/points/%7C${startLng}%2C${startLat}%3B${lng}%2C${lat}?m=${lng}%2C${startLat}%2F11.86`;
            window.open(url, '_blank');
        }
    };

    const translateDuration = (duration) => {
        const parts = duration.split(' ');
        let translatedDuration = '';

        if (parts.length === 4) {
            const hours = parts[0];
            const minutes = parts[2];
            translatedDuration = `${hours} сағат ${minutes} минут`;
        } else if (parts.length === 2) {
            const minutes = parts[0];
            translatedDuration = `${minutes} минут`;
        } else if (parts.length === 3) {
            const hours = parts[0];
            const minutes = parts[2];
            translatedDuration = `${hours} сағат ${minutes} минут`;
        } else {
            translatedDuration = duration;
        }

        return translatedDuration;
    };

    return (
        <div className="route-screen">
            <header className="map-header">Маршрут</header>
            <div ref={mapRef} className="map"></div>
            <div className="fixed-buttons">
                <button onClick={() => navigate('/')}>
                    <span>Басты бет</span>
                    <img className='icon30' src={home} alt='home' />
                </button>
                <button onClick={() => handleServiceButtonClick("train")}>
                    <span>Пойыз</span>
                    <img className='icon30' src={train} alt='train' />
                </button>
                <button onClick={() => handleServiceButtonClick("taxi")}>
                    <span>Такси</span>
                    <img className='icon30' src={taxi} alt='taxi' />
                </button>
                <button onClick={() => handleServiceButtonClick("tour")}>
                    <span>Тур</span>
                    <img className='icon30' src={tour} alt='tour' />
                </button>
                <button onClick={open2GIS}>
                    <span>2GIS</span>
                    <img className='icon31' src={twogis} alt='2gis' />
                </button>
            </div>
            {serviceData.length > 0 && (
                <div className="service-data">
                    <h2>Қызмет деректері</h2>
                    <ul>
                        {serviceData.map((data, index) => (
                            <div key={index}>
                                <p>{data.name}</p>
                                <p>{data.description}</p>
                                <p>{data.price}</p>
                                <p>{data.contact}</p>
                            </div>
                        ))}
                    </ul>
                </div>
            )}
            {routeInfo && (
                <div className="route-info">
                    <h2>Маршрут туралы ақпарат</h2>
                    <p>Қашықтық: {routeInfo.distance}</p>
                    <p>Уақыт: {translateDuration(routeInfo.duration)}</p>
                </div>
            )}
            <DangerModal
                show={showModal}
                onClose={handleCloseModal}
                message={modalMessage}
            />
        </div>
    );
};

export default NewRouteScreen;