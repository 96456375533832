import React, { useEffect } from 'react';
import '../styles/DangerModal.css';

const DangerModal = ({ message, show, onClose }) => {
    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                onClose();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [show, onClose]);

    return (
        show && (
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className="modal-body">
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        )
    );
};

export default DangerModal;